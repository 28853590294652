<template>
<div class="invite-card radius box-shadow">
  <div class="email">
    <label>Email:</label> {{this.email}}
  </div>
  <div class="role">
    <label>Role:</label> {{this.role}}
  </div>
  <div class="global-param" v-if="$permission('invite_user')">
    <div class="param ghost"></div>
    <div class="param" >
      <img src="@/assets/pictos/picto_param_purple.svg">
      <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
    </div>
    <div class="cont-param" >
      <div class="elem-param delete" @click="deleteInvite"
      >Delete</div>
    </div>
  </div>
  <!-- <div class="delete" @click="this.deleteInvite">Delete </div> -->
</div>

</template>

<script>

export default {
  name: 'InviteEntry',
  props: ['email', 'expires', 'role', 'deleteInvite'],
  computed: {
  },
  methods: {
    roleLabel(roleID) {
      const obj = this.$store.state.company.roles.find((o) => o._id === roleID);
      return obj.name;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .invite-card{
    padding: 0.5vh;
    position: relative;
    background-color: #ffffff;
    margin-bottom: 1vh;
    .email{
      font-size: $fnt-14;
    }
    .role{
      margin-top: 0.5vh;
      font-size: $fnt-12;
    }
  }
</style>
