<template>
<settings-page :extraComponents="componentList"/>
</template>

<script>

import EmployeeList from '@/components/EmployeeComponents/Employee/EmployeeList.vue';
import SettingsPage from '@/views/Universal/Settings/SettingPage.vue';
import settingsUsers from '@/components/EmployeeComponents/CompanySettings/settingsUsers.vue';

export default {
  name: 'CompanySettings',
  components: {
    SettingsPage,
  },
  data() {
    return {
      componentList: [
        {
          name: 'Employees',
          component: EmployeeList,
        },
        {
          name: 'User Settings',
          component: settingsUsers,
        },

      ],
    };
  },
};
</script>
