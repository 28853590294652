<template>
<div>

  <div class="cont-employee-list">
    <loading v-if="loading" />
    <div class="inner-list-employee" id="employee-list" v-else>
      <employee v-for="employee in employees" :employeeData="employee" :key="employee._id" />
    </div>
  </div>

</div>
</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';
import scrollInit from '@/modules/mixins/perfectScrollInit';
import Employee from './_employee.vue';

export default {
  name: 'EmployeeList',
  components: {
    Loading,
    Employee,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store.dispatch('company/getEmployees').then(() => {
      this.loading = false;
      scrollInit('#employee-list');
    });
  },
  computed: {
    employees() {
      return this.$store.state.company.companyEmployees;
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .cont-employee-list{
    padding: 1vh;
    height: 65vh;
    .sub-title{
      font-size: 30px;
      font-weight: 600;
      border-bottom: 2px solid $border_gray;
      padding: 20px 0;
    }
    .inner-list-employee{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      .elem-card{
        width: calc(calc(100% - 5vh) / 2) !important;
        margin-right: 1vh;
        padding: 1vh;
        margin-top: 0;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
</style>
