<template>
  <div class="role-form">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="radius marginB">
      <div class="subtitle-page no-padding">
        Add a new role
      </div>
    </div>
    <div class="name-entry entry-role-form radius marginB">
      <label class="label">Role's name</label>
      <input class="v-select" v-model="name" placeholder="name" requiredVal="name"/>
    </div>
    <div class="permissions entry-role-form radius marginB">
      <label class="label">Role's permissions</label>
      <v-select
        :options="permissionOptions"
        v-model="permissions"
        placeholder="Permissions"
        :multiple="true"
        requiredVal="permissions"
      />
    </div>

    <div class="submit">
      <div class="btn-green radius" @click="submit" >Submit</div>
      <div class="btn-green radius reverse" @click="cancel">Cancel</div>
    </div>

  </div>
</template>

<script>
import Permissions from '@/modules/auth/auth_permissions';
import Validate from '@/modules/mixins/validate';

export default {
  name: 'RoleForm',
  props: ['roleEntry'],
  mixins: [Validate],
  data() {
    return {
      name: '',
      permissions: [],
      permissionOptions: Permissions,
      new: true,
    };
  },
  methods: {
    submit() {
      if (this.validateFields()) {
        if (this.new) {
          this.$store.dispatch('company/addRole', { name: this.name, permissions: this.permissions });
        } else {
          this.$store.dispatch('company/editRole', { name: this.name, permissions: this.permissions, _id: this.roleEntry._id });
        }
        this.$modal.hide('roleform');
      }
    },
    cancel() {
      this.$modal.hide('roleform');
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'roleform');
    },
  },

  mounted() {
    if (this.roleEntry) {
      this.name = this.roleEntry.name;
      this.permissions = this.roleEntry.permissions;
      this.new = false;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.role-form{
  position: relative;
  padding: 1vh;
  .entry-role-form{
    margin-top: 2vh;
    background-color: $new_bg_grey;
    padding: 1vh;
    .v-select{
      margin-top: 1vh;
    }
  }
  .submit{
    width: calc(100% - 1vh);
    margin-top: 2vh;
    margin-right: 1vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-green{
      &:first-child{
        margin-right: 1vh;
      }
    }
  }
}
</style>
