<template>
  <div class="settingsusers" v-if="$permission('invite_user')" >
    <div class="rolemngt" v-if="$permission('edit_roles')">
      <div class="subtitle-page">Roles Management</div>
      <div class="radius">
        <div class="list-role">
          <div class="elem-role radius boxshadow-middle"
          v-for="role in roles"
          :key="role._id"
          >
            <div class="label">{{role.name}}</div>
            <div class="global-param">
              <div class="param ghost"></div>
              <div class="param" >
                <img src="@/assets/pictos/picto_param_purple.svg">
                <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
              </div>
              <div class="cont-param">
                <div class="elem-param" @click="() => edit(role)">Edit Role</div>
                <div class="elem-param" style="color: crimson"
                  @click="() => deleteRole(role)"> Delete</div>
              </div>
            </div>
          </div>
          <div class="cont-btn">
            <div class=" btn-green radius" @click="addNew">
              Add New Role
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="invite-cont">
      <invite-list :label="'Invite a New Employee'" />
    </div>
    <br>
  </div>
</template>

<script>
import InviteList from '@/components/EmployeeComponents/Invite/InviteList.vue';
import RoleForm from '@/components/EmployeeComponents/CompanySettings/_roleForm.vue';

export default {
  name: 'settingsUsers',
  components: {
    InviteList,
  },
  computed: {
    roles() {
      return this.$store.state.company.roles;
    },
  },
  methods: {
    addNew() {
      this.openModal(false);
    },
    edit(role) {
      this.openModal(role);
    },
    deleteRole(role) {
      this.$store.dispatch('company/deleteRole', role._id).then((result) => {
        if (!result) {
          this.$toastError('Can\'t delete a role in use!');
        }
      });
    },
    openModal(role) {
      this.$modal.show(RoleForm,
        {
          roleEntry: role,
        },
        {
          width: '50%',
          adaptive: true,
          height: 'auto',
          name: 'roleform',
          classes: ['overflow'],
        });
    },
  },
  mounted() {
    if (this.$permission('edit_roles')) {
      this.$store.dispatch('company/getRoles');
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .settingsusers{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .invite-cont{
    width: 45%;
  }
  .rolemngt{
    position: relative;
    margin-right: 5%;
    width: 50%;
    .subtitle-page{
      margin-bottom: 2vh;
      }
    .list-role{
      display: flex;
      flex-direction: column;
      // padding: 1vh;
      .elem-role{
        position: relative;
        width: calc(100% - 1vh);
        background-color: #ffffff;
        padding: 0.5vh;
        margin: 0.5vh 0;
        line-height: 3vh;
        height: 3vh;
        &:last-child{
          margin-bottom: 0;
        }
        .global-param{
          top: 1.1vh;
        }
      }
    }
  }
</style>
