<template>
  <div id="inviteContainer">
    <div id="sendContainer" v-if="!sendingStatus " >
      <div v-if="errorStatus">
        {{errorStatus}}
      </div>
      <div class="block-settings radius boxshadow-middle">
        <div class="cont-settings" style="height:auto;padding-top:10px;">
          <div class="elem-setting invitform radius">
            <div class="cont-label-elem">
              <div class="label">
                Email
              </div>
              <div class="elem">
                <input class="v-select" v-model="inviteEmail" requiredVal="inviteEmail" />
              </div>
            </div>
          </div>
          <div class="elem-setting invitform radius">
            <div class="cont-label-elem">
              <div class="label">
                Role
              </div>
              <div class="elem" v-if="rolesLoaded">
                <v-select
                  :options="rolesCompany"
                  v-model="inviteRole"
                  label="name"
                  placeholder="Role"
                  requiredVal="inviteRole"
                />
              </div>
            </div>
          </div>
          <div class="cont-btn">
            <div class="btn-green radius" @click="submitInvite">Submit</div>
          </div>
        </div>
      </div>

      <!-- <label> Email: </label>
      <input v-model="inviteEmail" />
      <br />
      <label>Role:</label>
      <select v-model="inviteRole">
        <option disabled value="">Please select one</option>
        <option value="Admin">Admin</option>
        <option value="Manager">Manager</option>
      </select>
      <button @click="submitInvite">Submit</button>
      <div v-if="errorStatus">
        {{errorStatus}}
      </div> -->
    </div>

    <div id="statusContainer" v-else>
      {{sendingStatus}}
    </div>
  </div>
</template>

<script>
import validateMixin from '@/modules/mixins/validate';

export default {
  name: 'InviteForm',
  mixins: [validateMixin],
  data() {
    return {
      sendingStatus: false,
      inviteEmail: '',
      inviteRole: null,
      errorStatus: false,
      rolesLoaded: false,
      roles: [],
    };
  },
  computed: {
    rolesCompany() {
      return this.$store.state.company.roles;
    },
  },
  methods: {
    submitInvite() {
      if (this.validateFields()) {
        this.errorStatus = false;
        if (!this.validateEmail(this.inviteEmail)) {
          this.errorStatus = 'Please enter a valid Email';
        } else {
          this.sendingStatus = 'Sending ...';
          this.$api.post('company/invite', { email: this.inviteEmail, role: this.inviteRole._id, roleName: this.inviteRole.name }).then((res) => {
            if (res.status < 300) {
              this.sendingStatus = 'Email sent!';
              this.$emit('sent-invite', res.data);
            }
          }).catch(() => {
            this.errorStatus = 'Error creating your invite, check your invitee address and if issue persists contact an administrator';
          });
        }
      } else {
        this.errorStatus = 'Please fill out both fields';
      }
    },
  },
  mounted() {
    this.roles = this.$store.getters['company/listRoles'];
    if (!this.roles.length > 1) {
      this.$store.dispatch('company/getRoles').then(() => {
        this.roles = this.$store.getters['company/listRoles'];
        this.rolesLoaded = true;
      });
    } else {
      this.rolesLoaded = true;
    }
  },
};
</script>

<style lang="scss">
  #sendContainer{
    .block-settings{
      width: 100% !important;
      margin: 0;
      // padding-bottom: 10px;
      &.radius{
        overflow: visible;
      }
      .elem-setting{
        width: calc(100% - 2vh);
        margin: 0;
        margin-bottom: 1vh;
        &.invitform{
          padding: 0 !important;
        }
        &:last-child{
          margin: 0;
        }
        &.radius{
          overflow: visible;
        }
        .v-select{
          border-radius: 0.5vh;
          // overflow: hidden;
          background-color: #fff;
          .vs__selected-options{
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
</style>
