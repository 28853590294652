<template>
  <div class="elem-card radius box-shadow contentbox">
    <div class="cont-info">
      <div class="picture"><img :src="this.employeeData.profilePicture" /></div>
      <div class="infos">
        <div class="name">
          {{this.employeeData.user.name.firstName || ''}}
          {{this.employeeData.user.name.lastName || ''}}
        </div>
        <div class="title" v-if="!this.editRole">
          {{this.employeeData.role ? this.employeeData.role.name: 'No Role'}}
        </div>
        <div class="title editrole" v-else>
          <v-select
            :options="roleOptions"
            v-model="newRole"
            :clearable="false"
            placeholder="Role"
            label="name"
            :reduce="role => role._id"
          />
          <div class="cont-edit-elem-btn">
            <div class="elem-edit cancel" @click="cancel">Cancel</div>
            <div class="elem-edit" @click="submitNewRole">Submit</div>
          </div>
        </div>
      </div>
    </div>

    <div class="global-param">
      <div class="param ghost"></div>
      <div class="param" >
        <img src="@/assets/pictos/picto_param_purple.svg">
        <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
      </div>
      <div class="cont-param">
        <div class="elem-param" @click="sendMessage">Send a message</div>
        <div class="elem-param" @click="toggleEdit" v-if="$permission('edit_roles')">
          Edit Role</div>
      </div>
    </div>

  </div>
</template>

<script>
import MessageModal from '@/components/UniversalComponents/Messages/newMessageModal.vue';

export default {
  name: 'EmployeeEntry',
  props: ['employeeData'],
  data() {
    return {
      editRole: false,
      newRole: '',
    };
  },
  computed: {
    roleOptions() {
      return this.$store.state.company.roles;
    },
  },
  methods: {
    sendMessage() {
      this.$modal.show(MessageModal, {
        recepient: this.employeeData.user,
      }, {
        name: 'new-message',
        width: '50%',
        height: 'auto',
        scrollable: true,
        adaptive: true,
        classes: ['overflow'],
      });
    },

    toggleEdit() {
      if (this.employeeData.role) {
        this.newRole = this.employeeData.role._id;
      } else {
        this.newRole = '';
      }
      this.editRole = true;
    },
    submitNewRole() {
      this.$store.dispatch('company/assignRole', { accountId: this.employeeData._id, roleId: this.newRole });
      this.editRole = false;
    },
    cancel() {
      this.editRole = false;
    },

  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .elem-card{
    position: relative;
    width: calc(calc(100% - 1vh) / 2);
    margin-top: 1vh;
    background-color: #ffffff;
    &:nth-child(1){
      margin-top: 0;
    }
    &:nth-child(0){
      margin-top: 0;
    }
    .cont-info{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .picture{
        height: 6vh;
        width: 6vh;
        border-radius: 50%;
        background-color: $logo_color_dark;
        overflow: hidden;
        img{
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .infos{
        width: 80%;
        margin-left: 1vh;
      }
    }
  }
  .editrole{
    width: 100%;
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .v-select{
      width: 60%;
    }
    .cont-edit-elem-btn{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 15%;
      .elem-edit{
        font-size: $fnt-14;
        margin-right: 0.5vh;
        cursor: pointer;
        &:last-child{
          margin-right: 0;
        }
        &.cancel{
          font-size: $fnt-12;
          color: $red_color;
        }
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
    // .elem-card{
    //   .border-top{
    //     height: 4px;
    //     width: 80%;
    //     background-color: $logo_color;
    //     margin-left: 20%;
    //   }
    //   .infos{
    //     position: relative;
    //     padding: 10px 10px 20px 120px;
    //     text-align: left;
    //     width: calc(100% - 130px);
    //     font-size: 16px;
    //     overflow: visible;
    //     .name{
    //       font-size: 20px;
    //       font-weight: 600;
    //     }
    //     .title{
    //       font-size: 14px;
    //       font-style: italic;
    //     }
    //   }
    //   .foot{
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: flex-start;
    //     padding: 10px 20px;
    //     color: $fond_gray;
    //     font-size: 12px;
    //     cursor: pointer;
    //     &:hover{
    //       text-decoration: underline;
    //     }
    //     .picto{
    //       width: 30px;
    //       height: 30px;
    //       border-radius: 50%;
    //       background-color: $logo_color_dark;
    //       margin-right: 10px;
    //       img{
    //         width: 100%;
    //       }
    //     }
    //   }
    // }
</style>

<style lang="scss">
.left{
  .EmployeeEntry{
    width: 100%;
  }
}
</style>
