<template>
  <div class="main-container flex-container flex-full">
    <div class="title-page">
      Settings
    </div>
    <div class="setting-options">
      <div class="select-group">
        <div v-for="(section, index) in this.optionSections"
            :key="section.name"
            class="option"
            @click="() => {selectedIndex = index;}"
            :class="{selection: index === selectedIndex}"
            >
            {{section.name}}
            <div class="underline" v-if="index === selectedIndex" />
          </div>
      </div>
      <div class="setting-content-cont" id="left-cont">
        <component :is="currentComp" v-bind="currentProps" />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSettings from '@/views/Universal/Settings/ProfileSettings.vue';
import NotificationList from '@/components/UniversalComponents/Notifications/allNotification.vue';
import '@/assets/scss/settings/selector.scss';

export default {
  name: 'UniversalSettings',
  data() {
    return {
      optionSections: [
        {
          name: 'Profile',
          component: ProfileSettings,
        },
        {
          name: 'Notifications',
          component: NotificationList,
        },
      ],
      selectedIndex: 0,
    };
  },
  props: ['extraComponents'],
  computed: {
    currentProps() {
      return {};
    },
    currentComp() {
      return this.optionSections[this.selectedIndex].component;
    },
  },

  mounted() {
    if (this.extraComponents) {
      this.optionSections.push(...this.extraComponents);
    }
    if (this.$route.query && this.$route.query.tab) {
      this.selectedIndex = parseInt(this.$route.query.tab, 10);
      this.$router.replace(this.$route.path);
    }
  },

};
</script>

<style lang="scss" scoped>
  .setting-options{
    width: 100%;
    .select-group{
      padding-left: 0;
    }
    .setting-content-cont{
      width: 70%;
      margin-top: 4vh;
      height: 70vh;
    }
    }
</style>
