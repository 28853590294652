<template>
  <div class="InviteList" >
    <loading v-if="loading" />

    <div id="list" v-else>
      <div class="subtitle-page entries">
        Add New Member
      </div>
      <div class="radius">
        <div id="entries" v-if="invites.length > 0" >
          <!-- {{invites}} -->
          <invite
            v-for="(invite, index) in invites"
            :key="invite._id"
            :email="invite.email"
            :role="invite.roleName"
            :expires="invite.expires"
            :deleteInvite="() => deleteInvite(index)"
          />
        </div>
        <div class="" v-else>
          Empty List
        </div>
        <div v-if="canEdit">
          <InviteForm v-if="showInvite" @sent-invite="sentInvite" />
          <div class="cont-btn" v-if="label">
            <div class=" btn-green radius"
              :class="showInvite ? 'reverse ': ''"
              @click="toggleInviteShow"
            >
              {{showInvite ? 'Hide ': `${label}`}}
            </div>
          </div>
          <div class="cont-btn" v-else>
            <div class="btn-green radius" @click="toggleInviteShow">
            {{showInvite ? 'Hide ': 'Add a New User'}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';
import Invite from './_invite.vue';
import InviteForm from './_inviteForm.vue';

export default {
  name: 'InviteList',
  props: ['label'],
  components: {
    Invite,
    InviteForm,
    Loading,
  },
  data() {
    return {
      invites: [],
      showInvite: false,
      loading: true,
    };
  },
  computed: {
    canEdit() {
      return this.$permission('invite_user');
    },
  },
  methods: {
    deleteInvite(index) {
      const invite = this.invites[index];
      this.$api.delete(`company/invite/${invite._id}`).then((res) => {
        if (res.status < 400) {
          this.invites.splice(index, 1);
        }
      });
    },
    sentInvite(inviteData) {
      this.invites.push(inviteData);
    },
    toggleInviteShow() {
      this.showInvite = !this.showInvite;
    },
  },
  mounted() {
    this.$api.get('company/invites').then((res) => {
      this.invites = res.data;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .InviteList{
    .subtitle-page{
    margin-bottom: 2vh;
  }
  .contentbox{
    padding: 2vh;
  }
    width: calc(100% - 2vh);
  }

  .cont-btn{
    display: flex;
  }
</style>

<style lang="scss">
  // .left{
  //   .InviteList{
  //     display: none;
  //   }
  // }
</style>
