<template>
<div class="notification-container">
  <div id="noti-list">
    <div
      class="list-notifications large-list"  v-if="notifications.length > 0">
      <notification
        v-for="noti in notifications"
        :notification="noti"
        :key="noti._id"
        :closeActive="false"
      />

    </div>
    <div class="list-notifications large-list" v-else>
      <div class="empty-status">
        No Notifications
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button class="btn-green radius" @click="clearSeen">Delete Read Notifications</button>
    <button class="btn-green radius" @click="markAllAsSeen">Mark All as Read</button>

  </div>
</div>
</template>

<script>
import notification from '@/components/UniversalComponents/Notifications/_notification.vue';
import scrollInit from '@/modules/mixins/perfectScrollInit';

export default {
  name: 'allNotification',
  components: {
    notification,
  },
  computed: {
    notifications() {
      const elem = this.$store.state.user.notifications;
      elem.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return elem;
    },
  },
  methods: {
    clearSeen() {
      this.$store.dispatch('user/clearSeenNotifications');
    },
    markAllAsSeen() {
      this.$store.dispatch('user/setNotificationsAsSeen');
    },
  },

  mounted() {
    scrollInit('#noti-list');
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.notification-container{
  display: flex;
  flex-direction: row;
#noti-list{
  position: relative;
  height: 72vh;
  width: 95%;
}
.cont-list-all{
  position: relative;
  width: 100%;
  overflow: hidden;
  top: 10vh;
  .inner-list{
    position: relative;
    padding-right: 6px;
    .elem-notif{
      margin-left: 0;
    }
    &.no-notif{
      color: $font_gray_color;
      font-size: $classic_min_fs;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      padding: 0;
    }
  }
}
.large-list{
  min-height: 5vh;
  .empty-status{
    margin-left: 1vw;
    height: 5vh;
    line-height: 5vh;
    font-size: 2vh;
  }
  .notification-wrapper{
    position: relative;
    flex-direction: row;
    display: flex;
    .delete{
      cursor: pointer;
      display: flex;
      width: 2vh;
      justify-content: center;
      transition: all .2s;
      &:hover{
        opacity: 0.5 !important;
      }
    }
  }
  .elem-notif{
    cursor: pointer;
    margin: 5px;
    width: 90%;
    padding: 5px 10px;
    border: 1px solid #ccc;
    position: relative;
    transition: all .1s;
    line-height: 3vh;
    height: 3vh;
    .label-notif{
    font-size: 1.5vh !important;

    }
    .date{
      width: 100%;
      text-align: right;
      padding: 0;
      margin: 0;
    }
    &:hover{
      opacity: 0.5 !important;
    }

  }
}
.btn-container{
  display:flex;
  align-content: center;
  flex-direction: column;
  button{
    margin: 1vh;
  }
}
}
</style>
